import {FormikHelpers} from 'formik'
import {KTSVG} from '../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, loadRegisteredCardsAsync} from '../../redux/commonSlice'
import {getProductState, startPaymentAsync} from '../../redux/productSlice'
import {useNavigate} from 'react-router-dom'
import Translator from '../../components/common/translate/Translator'
import PaymentContractPopup from '../../components/purchase/PaymentContractPopup'
import Discount from '../../components/purchase/Discount'
import FormikForm from '../../components/form/FormikForm'
import CartDetails from '../../components/purchase/CartDetails'
import CardForm from '../../components/customer/finance/card/CardForm'
import {
  CardFormValues,
  CardInitialValues,
  CardSchema,
  NewCardTokenValue,
} from '../../utils/cardUtilities'
import {FormStatus} from '../../types/FormStatus'
import FormMessage from '../../components/common/messages/FormMessage'
import NormalTitle from '../../components/common/title/NormalTitle'
import {useEffect} from 'react'
import CardSelection from '../../components/customer/finance/card/CardSelection'
import CardSelectionNew from '../../components/customer/finance/card/CardSelectionNew'
import {defterdar_log, getCurrencySymbol} from '../../utils/utilities'
import {CurrencyEnum} from '../../apiClient/enums'
import {getCompanyState} from '../../redux/companySlice'

function CartPaymentPage() {
  const productState = useSelector(getProductState)
  const commonState = useSelector(getCommonState)
  const companyState = useSelector(getCompanyState)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(loadRegisteredCardsAsync())
  }, [])

  const submitStep = async (values: CardFormValues, actions: FormikHelpers<CardFormValues>) => {
    try {
      await dispatch<any>(
        startPaymentAsync({
          request: {
            cardHolderName: values.nameOnCard,
            cardNumber: values.cardNumber,
            cvc: values.cardCvv,
            expireMonth: values.cardExpiryMonth,
            expireYear: values.cardExpiryYear,
            registerCard: values.registerCard,
            cardName: values.cardName,
            selectedCardToken: values.cardToken == NewCardTokenValue ? null : values.cardToken,
            paymentId: productState?.certainPaymentId,
          },
        })
      )
        .unwrap()
        .then((message: string) => {
          actions.setStatus(new FormStatus(false, message))
        })
    } catch (error) {
      console.error(error)
    } finally {
    }
  }

  const initValues: CardFormValues = {
    ...CardInitialValues,
    cardToken:
      (commonState.registeredCards?.length ?? 0) > 0
        ? commonState.registeredCards?.at(0)?.cardToken ?? null
        : NewCardTokenValue ?? null,
  }

  return (
    <>
      <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-10 h-100'>
        <div className='card justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-400px w-xxl-400px me-9 mb-10'>
          <div className='card-body pt-10 mb-10' style={{paddingLeft: 0, paddingRight: 0}}>
            <CartDetails />
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-center bg-body rounded w-100 mb-10 pb-10'>
          <div className={'w-100 h-100 d-flex flex-row-fluid '}>
            <FormikForm
              initialValues={initValues}
              onSubmit={submitStep}
              validationSchema={CardSchema}
            >
              {(form_props) => {
                return (
                  <div className='d-flex flex-center'>
                    <div className='w-100 mt-5'>
                      <NormalTitle text='ODEME' />

                      <div className='d-flex flex-center'>
                        <div className='row w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50 w-xxl-50'>
                          {commonState.registeredCards?.map((card) => (
                            <div key={card.cardToken} className='col-12 mb-5 px-5'>
                              <CardSelection card={card} setFieldValue={form_props.setFieldValue} />
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className='d-flex flex-center'>
                        <div className='w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50 w-xxl-50'>
                          <div className='col-6 mb-5 px-5 w-100'>
                            {(commonState.registeredCards?.length ?? 0) > 0 && (
                              <CardSelectionNew setFieldValue={form_props.setFieldValue} />
                            )}
                          </div>
                          {(form_props.values.cardToken == NewCardTokenValue ||
                            (commonState.registeredCards?.length ?? 0) == 0) && (
                            <CardForm
                              isCardRegisterDisabled={
                                productState?.prices?.items?.some(
                                  (s) => (s.serviceLength ?? 0) > 1
                                ) ?? false
                              }
                              showCardNameInput={form_props.values.registerCard}
                            />
                          )}

                          {companyState.data?.company?.isWizardFinished && <Discount />}

                          <div className='px-5'>
                            <PaymentContractPopup />
                          </div>

                          <div className='px-5 mt-5'>
                            <FormMessage {...form_props.status} />
                          </div>

                          <div className='d-flex flex-stack align-items-end mt-2 mb-25'>
                            <div className='mr-2'>
                              <button
                                onClick={function () {
                                  navigate(-1)
                                }}
                                type='button'
                                className='btn btn-lg btn-light-primary me-3'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr063.svg'
                                  className='svg-icon-4 me-1'
                                />
                                <Translator name='GERI' />
                              </button>
                            </div>
                            <div>
                              <button
                                type='submit'
                                disabled={!form_props.isValid}
                                className='btn btn-lg btn-primary me-3'
                              >
                                <span className='indicator-label'>
                                  <Translator
                                    name='ODE'
                                    params={{
                                      Price:
                                        productState.prices?.totalAmountWithDiscount?.toFixed(1),
                                      Currency: getCurrencySymbol(
                                        productState.prices?.items?.at(0)?.currencyId ??
                                          CurrencyEnum.Türk_Lirası
                                      ),
                                    }}
                                  />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            </FormikForm>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartPaymentPage
