import {useDispatch, useSelector} from 'react-redux'
import {getAdminState} from '../../../redux/adminSlice'
import _ from 'lodash'
import PaymentInvoiceTable from './PaymentInvoiceTable'
import PaymentInvoiceFilter from './PaymentInvoiceFilter'
import PaymentInvoiceSummary from './PaymentInvoiceSummary'

function PaymentInvoicesPage() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  return (
    <>
      <PaymentInvoiceFilter />
      <PaymentInvoiceSummary />
      <PaymentInvoiceTable />
    </>
  )
}

export default PaymentInvoicesPage
