import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useRef, useState} from 'react'
import {
  AdminPanelCompanyDTOIEnumerableApiData,
  AdminPanelCompanyDetailDTO,
  AdminPanelCompanyDetailDTOApiData,
  BooleanApiData,
  GetAdminCompaniesRequest,
  GetAdminCompanyRequest,
  UpdateCompanyRequest,
  UpdateUserActiveStatusRequest,
} from '../../../api/data-contracts'
import {FormikValues} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {closeAreYouSureModal, postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {getAdminState, setCompanies} from '../../../redux/adminSlice'
import {
  Api_Admin_GetCompanies,
  Api_Admin_GetCompany,
  Api_Admin_ChangeUserActive,
  Api_Admin_UpdateCompany,
} from '../../../apiClient/apiClient'
import {ApplicationStepEnum, UserTypeAuthorizationsEnum} from '../../../apiClient/enums'
import * as Yup from 'yup'
import {CanIDoThat} from '../../../utils/CanIDoThat'
import PaymentsTable from '../../../components/customer/finance/PaymentsTable'
import TextWithClipBoard from '../../../components/other/TextWithClipBoard'
import FormikForm from '../../../components/form/FormikForm'
import FormMessage from '../../../components/common/messages/FormMessage'
import FormInput from '../../../components/form/FormInput'
import CustomDropdown from '../../../components/form/dropdown/CustomDropdown'
import {setFormikStatus} from '../../../utils/utilities'
import AreYouSurePopup from '../../../components/common/popup/AreYouSurePopup'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import FormCheckboxInput from '../../../components/form/FormCheckboxInput'
import PhoneNumberInput from '../../../components/form/PhoneNumberInput'

function AdminCompanyDetailPopup(props: {companyGuidId?: string}) {
  const schema = Yup.object().shape({
    name: Yup.string().required('SIRKET_KISA_ADI_ZORUNLUDUR'),
    phoneNumber: Yup.string()
      .phone('TR', false, 'TELEFON_NUMARASI_TELEFON')
      .required('TELEFON_NUMARASI_REQUIRED'),
    email: Yup.string()
      .email('EPOSTA_ADRESI_EPOSTA')
      .min(3, 'EPOSTA_ADRESI_MIN')
      .max(50, 'EPOSTA_ADRESI_MAX')
      .required('EPOSTA_ADRESI_REQUIRED'),
  })

  const [modalShow, setModalShow] = useState(false)

  const [company, setCompany] = useState<AdminPanelCompanyDetailDTO | null | undefined>(null)

  const adminstate = useSelector(getAdminState)

  const formikRef = useRef(null)
  const intl = useIntl()

  const dispatch = useDispatch()

  let initData: UpdateCompanyRequest = {
    taxNumber: company?.company?.taxAdministrationNumber ?? '',
    companyId: company?.company?.guid ?? '',
    canOpenMultipleCompany: false,
    financialAdvisorUserId: company?.assignedFinancialAdvisorUserId ?? 0,
    name: company?.company?.shortName ?? '',
    stepId: company?.company?.stepId ?? 0,
    referenceId: company?.users?.at(0)?.referenceId,
    isActive: company?.company?.isActive ?? false,
    suspensionReasonId: company?.company?.suspensionReasonId ?? 0,
    isInspectionApproved: company?.company?.isInspectionApproved ?? false,
    phoneNumber: company?.users?.at(0)?.phoneNumber,
    email: company?.users?.at(0)?.mailAddress,
  }
  const handleModalClose = () => setModalShow(false)

  const load = () => {
    const body: GetAdminCompanyRequest = {
      companyGuidId: props.companyGuidId,
    }
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetCompany,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDetailDTOApiData) {
        setCompany(response.data)
        dispatch(setIsLoading(false))
        setModalShow(true)
      })
  }

  const submit = async (values: UpdateCompanyRequest, actions: FormikValues) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpdateCompany,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        setFormikStatus(actions, response)
        load()
      })
  }

  const changeUserActive = async () => {
    dispatch(setIsLoading(true))
    const body: UpdateUserActiveStatusRequest = {
      companyId: props.companyGuidId,
      isActive: company?.users?.at(0)?.isActive ? false : true,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_ChangeUserActive,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(closeAreYouSureModal())
        await loadCompanies({})
        dispatch(setIsLoading(false))
      })
  }

  const loadCompanies = async (values: GetAdminCompaniesRequest) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetCompanies,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: AdminPanelCompanyDTOIEnumerableApiData) {
        dispatch<any>(setCompanies(response.data))
        //setFilteredCompanies(response.data ?? [])
        dispatch(setIsLoading(false))
      })
  }

  return (
    <>
      <a
        href='#'
        onClick={() => {
          load()
        }}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
      </a>

      <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
        <Modal.Header closeButton>
          <h5 className='modal-title'>{company?.company?.fullName} Şirketi Bilgileri </h5>
        </Modal.Header>
        <Modal.Body>
          {
            <FormikForm
              initialValues={initData}
              onSubmit={submit}
              validationSchema={schema}
              innerRef={formikRef}
            >
              {(props) => {
                return (
                  <>
                    <div className='card-header card-header-stretch'>
                      <div className='card-toolbar'>
                        <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
                          <li className='nav-item'>
                            <a
                              className='nav-link active'
                              data-bs-toggle='tab'
                              href='#kt_tab_pane_1'
                            >
                              Bilgiler
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                              Ödemeler
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='card-body'>
                      <div className='tab-content' id='myTabContent'>
                        <div
                          className='tab-pane fade show active'
                          id='kt_tab_pane_1'
                          role='tabpanel'
                        >
                          <div className='w-100 mt-5'>
                            <div className='row mb-7'>
                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Aktif</label>
                              </div>
                              <div className='col-lg-4'>
                                <FormCheckboxInput
                                  description=''
                                  name='isActive'
                                  setFieldValue={props.setFieldValue}
                                />
                              </div>

                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Yoklama Onay</label>
                              </div>
                              <div className='col-lg-4'>
                                <FormCheckboxInput
                                  description=''
                                  name='isInspectionApproved'
                                  setFieldValue={props.setFieldValue}
                                />
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Firma Adı</label>
                              </div>
                              <div className='col-lg-4'>
                                <FormInput
                                  fieldAttributes={{
                                    type: 'text',
                                    name: 'name',
                                    placeholder: '',
                                    disabled: !CanIDoThat(
                                      UserTypeAuthorizationsEnum.Admin_Panel_Company_Update
                                    ),
                                  }}
                                  istouched={props.touched.taxNumber ?? false}
                                  errorMessage={props.errors.taxNumber}
                                  placeHolderOnTop={false}
                                  checkValid={false}
                                />
                              </div>

                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Firma Durum</label>
                              </div>

                              <div className='col-lg-4'>
                                <CustomDropdown
                                  fieldAttributes={{
                                    name: 'stepId',
                                    placeholder: '',
                                    onChange: (event: any) => {},
                                  }}
                                  noSelectionText='FIRMA_DURUM_SECINIZ'
                                  options={_.orderBy(
                                    Object.entries(ApplicationStepEnum)
                                      ?.filter((f) => {
                                        return isNaN(Number(f[0]))
                                      })
                                      .map(([key, value]) => ({
                                        label: intl.formatMessage({id: key}) ?? '',
                                        value: value ?? '',
                                      })) ?? [],
                                    (o) => o.label
                                  )}
                                  noSelectionValue={0}
                                  placeHolderOnTop={false}
                                  setFieldValue={props.setFieldValue}
                                  valueConverter={parseInt}
                                />
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <label className='col-lg-2 fw-bold text-muted'>Kullanıcı</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.users?.map((m) => (
                                    <span
                                      className='fw-bolder fs-6 text-dark d-flex'
                                      key={m.firstName ?? '' + m.lastName ?? ''}
                                    >
                                      {m.firstName} {m.lastName}
                                    </span>
                                  ))}
                                </span>
                              </div>
                              <label className='col-lg-2 fw-bold text-muted'>Adres</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.address?.fullAddressDescription}
                                </span>
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <label className='col-lg-2 fw-bold text-muted'>Email</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  <FormInput
                                    istouched={props.touched.email ?? false}
                                    errorMessage={props.errors.email}
                                    isTransparent={false}
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'email',
                                      autoComplete: 'off',
                                      placeholder: 'EMAIL',
                                    }}
                                  />
                                  {/* {company?.users?.map((m) => (
                                    <span className='fw-bolder fs-6 text-dark d-flex' key={m.guid}>
                                      <TextWithClipBoard text={m.mailAddress ?? ''} />
                                    </span>
                                  ))} */}
                                </span>
                              </div>
                              <label className='col-lg-2 fw-bold text-muted'> Telefon</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  <PhoneNumberInput
                                    istouched={props.touched.phoneNumber ?? false}
                                    errorMessage={props.errors.phoneNumber}
                                    isTransparent={false}
                                    setFieldValue={props.setFieldValue}
                                    fieldAttributes={{
                                      type: 'text',
                                      name: 'phoneNumber',
                                      autoComplete: 'off',
                                      placeholder: 'BOS_TELEFON',
                                    }}
                                  />
                                  {/* {company?.users?.map((m) => (
                                    <span className='fw-bolder fs-6 text-dark d-flex' key={m.guid}>
                                      <TextWithClipBoard text={m.phoneNumber ?? ''} />
                                    </span>
                                  ))} */}
                                </span>
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <label className='col-lg-2 fw-bold text-muted'>Şirket Tipi</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.company?.type}
                                </span>
                              </div>
                              <label className='col-lg-2 fw-bold text-muted'>Kurulum Durumu</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.company?.descriptionForAdvisor}/
                                  {company?.company?.stepDescription}
                                </span>
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <label className='col-lg-2 fw-bold text-muted'>Aldığı Ürünler</label>
                              <div className='col-lg-4'>
                                {company?.products?.map((m) => (
                                  <span className='fw-bolder fs-6 text-dark d-flex' key={m}>
                                    {m}
                                  </span>
                                ))}
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <label className='col-lg-2 fw-bold text-muted'>Ortak Sayısı</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.company?.partnerCount}
                                </span>
                              </div>
                              <label className='col-lg-2 fw-bold text-muted'>Personel Sayısı</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.company?.personnelCount}
                                </span>
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <label className='col-lg-2 fw-bold text-muted'>
                                Operasyon Adresi Durumu
                              </label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.company?.operationAddressType}
                                </span>
                              </div>
                              <label className='col-lg-2 fw-bold text-muted'>Sermaye</label>
                              <div className='col-lg-4'>
                                <span className='fw-bolder fs-6 text-dark'>
                                  {company?.company?.capitalAmount}
                                </span>
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Vergi Numarası</label>
                              </div>
                              <div className='col-lg-4'>
                                <FormInput
                                  fieldAttributes={{
                                    type: 'text',
                                    name: 'taxNumber',
                                    placeholder: '',
                                    disabled: !CanIDoThat(
                                      UserTypeAuthorizationsEnum.Admin_Panel_Company_Update
                                    ),
                                  }}
                                  istouched={props.touched.taxNumber ?? false}
                                  errorMessage={props.errors.taxNumber}
                                  placeHolderOnTop={false}
                                  checkValid={false}
                                />
                              </div>

                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Mali Müşavir</label>
                              </div>
                              <div className='col-lg-4'>
                                <CustomDropdown
                                  fieldAttributes={{
                                    name: 'financialAdvisorUserId',
                                    placeholder: '',
                                    onChange: (event: any) => {},
                                  }}
                                  noSelectionText='MALI_MUSAVIR_SECINIZ'
                                  options={
                                    adminstate.companiesStartup?.financialAdvisors?.map((m) => ({
                                      label: m.description ?? '',
                                      value: m.id ?? '',
                                    })) ?? []
                                  }
                                  noSelectionValue={0}
                                  placeHolderOnTop={false}
                                  setFieldValue={props.setFieldValue}
                                  valueConverter={parseInt}
                                />
                              </div>
                            </div>

                            <div className='separator border-3 my-3 mx-1'></div>

                            <div className='row mb-7'>
                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Referans</label>
                              </div>
                              <div className='col-lg-4'>
                                <CustomDropdown
                                  fieldAttributes={{
                                    name: 'referenceId',
                                    placeholder: '',
                                    onChange: (event: any) => {},
                                  }}
                                  noSelectionText=''
                                  options={
                                    adminstate.companiesStartup?.references?.map((m) => ({
                                      label: m.description ?? '',
                                      value: m.id ?? '',
                                    })) ?? []
                                  }
                                  noSelectionValue={0}
                                  placeHolderOnTop={false}
                                  setFieldValue={props.setFieldValue}
                                  valueConverter={parseInt}
                                />
                              </div>

                              <div className='col-lg-2  d-flex align-items-center'>
                                <label className='fw-bold text-muted'>Fesih Sebebi</label>
                              </div>
                              <div className='col-lg-4'>
                                <CustomDropdown
                                  fieldAttributes={{
                                    name: 'suspensionReasonId',
                                    placeholder: '',
                                    onChange: (event: any) => {},
                                  }}
                                  noSelectionText=''
                                  options={
                                    adminstate.companiesStartup?.companySuspensionReasons?.map(
                                      (m) => ({
                                        label: m.description ?? '',
                                        value: m.id ?? '',
                                      })
                                    ) ?? []
                                  }
                                  noSelectionValue={0}
                                  placeHolderOnTop={false}
                                  setFieldValue={props.setFieldValue}
                                  valueConverter={parseInt}
                                />
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col-lg-6 offset-3'>
                                <FormMessage {...props.status} />
                              </div>
                            </div>

                            {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Company_Update) && (
                              <div className='d-flex'>
                                <div className='p-2'>
                                  <AreYouSurePopup
                                    modalOpener={
                                      <a className='btn btn-lg btn-warning me-3 mt-2'>
                                        <span className='indicator-label'>
                                          {company?.users?.at(0)?.isActive
                                            ? 'Kişi Pasife Al'
                                            : 'Kişiyi Aktif Et'}
                                        </span>
                                      </a>
                                    }
                                    message={'EMIN_MISINIZ'}
                                    onClick={() => changeUserActive()}
                                  />
                                </div>

                                <div className='ms-auto p-2'>
                                  <button type='submit' className='btn btn-lg btn-primary mt-2'>
                                    <span className='indicator-label'>Kaydet</span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='tab-pane fade show' id='kt_tab_pane_2' role='tabpanel'>
                          <div className='w-100'>
                            <PaymentsTable
                              canPay={false}
                              payments={company?.payments ?? []}
                              canCancel={true}
                              load={load}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }}
            </FormikForm>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminCompanyDetailPopup
