import {useDispatch, useSelector} from 'react-redux'
import {postRequestAsync, setIsLoading} from '../../../redux/commonSlice'
import {
  BooleanApiData,
  PaymentInvoiceDTO,
  PrintInvoiceRequest,
  StringApiData,
} from '../../../api/data-contracts'
import {
  Api_Admin_CancelInvoice,
  Api_Admin_GetInviocePreview,
  Api_Admin_PrintInvoice,
} from '../../../apiClient/apiClient'
import {getAdminState, getPaymentInvoicesAsync} from '../../../redux/adminSlice'
import {KTSVG} from '../../../../_metronic/helpers'
import _ from 'lodash'
import moment from 'moment'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import BasePopup, {PopupProvides} from '../../../components/common/popup/BasePopup'
import clsx from 'clsx'
import filterFactory, {selectFilter, textFilter} from 'react-bootstrap-table2-filter'

function PaymentInvoiceTable() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  function printInvoice(guid: string) {
    const body: PrintInvoiceRequest = {
      guidId: guid,
    }
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_PrintInvoice,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: BooleanApiData) {
        await dispatch<any>(getPaymentInvoicesAsync())
      })
  }

  const preview = async (guid: string) => {
    const body: PrintInvoiceRequest = {
      guidId: guid,
    }

    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetInviocePreview,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: StringApiData) => {
        var win = window.open(response.data ?? '', '_blank')
        win?.focus()
        dispatch(setIsLoading(false))
      })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'paymentId',
      text: 'E-Fatura',
      sort: true,
      headerClasses: '',
      classes: '',
      width: 500,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            {m.isUbl === true ? (
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2hx'
              />
            ) : (
              <></>
            )}
          </div>
        </>
      ),
    },
    {
      dataField: 'userName',
      text: 'Kişi ve Firma',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '20%'},
      filter: textFilter(),
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-7'>{m.userName}</div>
          <div className='text-dark fw-bold d-block fs-7'>{m.companyName}</div>
        </>
      ),
    },
    {
      dataField: 'stateId',
      text: 'Ödeme Durumu',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{m.paymentState}</div>
      ),
      filter: selectFilter({
        options:
          _.sortBy(
            _.uniqBy(
              adminState.invoices?.map((m) => {
                return {
                  value: m.stateId ?? 0,
                  label: m.paymentState ?? '',
                }
              }),
              (item) => `${item.value}-${item.label}` // Ensure uniqueness by both value and label
            ),
            'label' // Sort by the label property
          ) ?? [],
      }),
    },
    {
      dataField: 'einvoiceStateDescription',
      text: 'E-Fatura Durum',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='d-flex flex-row'>
          <div className='text-dark fw-bold d-block fs-7'>{m.einvoiceStateDescription}</div>

          <div className='mx-2'>
            {m.foribaWebServiceMessage && (m.isSuccessful ?? false) == false && (
              <BasePopup
                title='FATURA_HATA_MESAJI'
                size='xl'
                wrapperClassName='w-25'
                modalOpener={
                  <KTSVG path='media/icons/duotune/general/gen044.svg' className='svg-icon-hx' />
                }
              >
                {(providers: PopupProvides) => {
                  return <>{m.foribaWebServiceMessage}</>
                }}
              </BasePopup>
            )}
          </div>
        </div>
      ),
    },
    {
      dataField: 'dueDate',
      text: 'Ödeme Tarihi',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>
          {' '}
          {moment(m.dueDate).format('DD.MM.yyyy HH:mm')}
        </div>
      ),
    },
    {
      dataField: 'invoiceAmount',
      text: 'Fatura',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{Math.round(m.invoiceAmount ?? 0)} ₺</div>
      ),
    },
    {
      dataField: 'totalAmount',
      text: 'Toplam',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark fw-bold d-block fs-7'>{Math.round(m.totalAmount ?? 0)} ₺</div>
      ),
    },
    {
      dataField: 'preview',
      text: 'Önizleme / PDF',
      sort: true,
      headerClasses: 'mw-500px text-center',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center align-items-center'>
            {(m.documentLink?.length ?? 0 > 0) && (
              <div className='text-dark d-flex justify-content-center flex-column align-items-center  fw-bold d-block fs-7'>
                <div className='fs-8 me-2'>PDF</div>

                <a href={m.documentLink ?? ''} target='_blank'>
                  <KTSVG
                    path='/media/icons/duotune/files/fil003.svg'
                    className='svg-icon-muted svg-icon-2hx'
                  />
                </a>
              </div>
            )}

            <div className='text-dark d-flex justify-content-center flex-column align-items-center  fw-bold d-block fs-7 ms-3'>
              <div className='fs-8 me-2'>Önizleme</div>
              <a
                href={'#'}
                onClick={function () {
                  preview(m.uuid ?? '')
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/files/fil003.svg'
                  className='svg-icon-muted svg-icon-2hx'
                />
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      dataField: 'missingInfo',
      text: 'Eksik Bilgiler	',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: PaymentInvoiceDTO, rowIndex: number, colIndex: number) => (
        <div className='text-dark  justify-content-center fw-bold d-block fs-7'>
          {m.missingInfo}
        </div>
      ),
    },
  ]

  return (
    <div className={`card h-100 mt-5`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Ödeme ve Faturalar</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {(adminState.invoices?.length ?? 0) > 0 && (
            <>
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  totalSize: adminState?.invoices?.length,
                  sizePerPage: 100,
                  paginationSize: 100,
                  paginationTotalRenderer: (from, to, size) => (
                    <>
                      <span className='react-bootstrap-table-pagination-total ms-5'>
                        Toplam {size} faturadan {from} - {to} arası gösteriliyor.
                      </span>
                    </>
                  ),
                })}
              >
                {({paginationProps, paginationTableProps}) => (
                  <>
                    <div>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <PaginationTotalStandalone {...paginationProps} />
                      <PaginationListStandalone {...paginationProps} />
                      <BootstrapTable
                        {...paginationTableProps}
                        keyField='paymentId'
                        data={_.orderBy(adminState.invoices, (o) => o.dueDate, 'desc') ?? []}
                        filter={filterFactory()}
                        filterPosition='top'
                        columns={columns}
                        classes='table table-hover table-row-dashed table-rounded mt-5'
                      />
                    </div>
                  </>
                )}
              </PaginationProvider>
            </>
          )}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default PaymentInvoiceTable
