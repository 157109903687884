import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {getAdminState} from '../../../redux/adminSlice'
import PaymentInvoiceSummaryCard from './PaymentInvoiceSummaryCard'

function PaymentInvoiceSummary() {
  const adminState = useSelector(getAdminState)

  return (
    <div className={`card h-100 mt-3`}>
      <div className='card-body py-3'>
        <div className='row'>
          <PaymentInvoiceSummaryCard
            description={(
              adminState?.invoices?.filter(
                (f) => f.isSuccessful == true && f.isPaymentSuccessful == true
              )?.length ?? 0
            ).toString()}
            title=' Ödenmiş Basılan'
            color='bg-primary'
          />
          <PaymentInvoiceSummaryCard
            description={
              (
                _.sumBy(
                  adminState?.invoices?.filter(
                    (f) => f.isSuccessful == true && f.isPaymentSuccessful == true
                  ),
                  (o) => o.invoiceAmount ?? 0
                ) ?? 0
              )
                .toFixed(0)
                .toString() + ' ₺'
            }
            title=' Ödenmiş Basılan Tutar'
            color='bg-success'
          />
          <PaymentInvoiceSummaryCard
            description={(
              adminState?.invoices?.filter(
                (f) => (f.isSuccessful ?? false) == false && f.isPaymentSuccessful == true
              )?.length ?? 0
            ).toString()}
            title=' Ödenmiş Basılmayan '
            color='bg-warning'
          />
          <PaymentInvoiceSummaryCard
            description={
              (
                _.sumBy(
                  adminState?.invoices?.filter(
                    (f) => (f.isSuccessful ?? false) == false && f.isPaymentSuccessful == true
                  ),
                  (o) => o.invoiceAmount ?? 0
                ) ?? 0
              )
                .toFixed(0)
                .toString() + ' ₺'
            }
            title=' Ödenmiş Basılmayan Tutar'
            color='bg-danger'
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentInvoiceSummary
