import React from 'react'

function PaymentInvoiceSummaryCard(props: {title: string; description: string; color: string}) {
  return (
    <div className='col-xl-3'>
      <div className='card card-xl-stretch'>
        <div className='card-body p-0'>
          <div className={'pt-7 pb-7 card-rounded h-100px w-100 ' + props.color}>
            <div className='d-flex text-center flex-column fs-7 fw-bold text-white'>
              <span>{props.title}</span>
            </div>

            <div className='d-flex text-center flex-column text-white pt-3'>
              <span className='fw-bold fs-2x pt-1'>{props.description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentInvoiceSummaryCard
